import React, { FC, ReactNode } from 'react';
import { Box, Card, makeStyles, Theme, Typography } from '@material-ui/core';
import Link from 'next/link';
import ReadButton from './ReadButton';

interface CardProps {
  title: string;
  text?: string;
  icon: ReactNode;
  image: string;
  link: string;
  isCareerCard?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(0, 7, 7),
    backgroundImage: ({ image }: Partial<CardProps>) => `url(${image})`,
    boxShadow: 'inset 1px -380px 205px -99px rgba(0, 0, 0, 0.8)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    cursor: 'pointer',
    borderRadius: 0,
    maxHeight: '100%',
    position: 'relative',
    willChange: 'box-shadow',
    transition: ' 250ms ease',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: 'inset 0px -578px 239px -291px rgba(125, 43, 0, 0.8)',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 448,
    },
  },
  textColor: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
}));

const ExpertiseCard: FC<CardProps> = (props) => {
  const { title, text, link, icon, image, isCareerCard = false } = props;
  const classes = useStyles({ image });

  return (
    <Link href={link}>
      <a>
        <Card className={classes.card}>
          <Box mb={15}>{icon}</Box>
          <Box mb={2.4}>
            <Typography className={classes.textColor} variant="h5">
              {title}
            </Typography>
          </Box>
          <Box mb={5}>
            <Typography className={classes.textColor} variant="body2">
              {text}
            </Typography>
          </Box>
          <ReadButton href={link} text={isCareerCard ? 'Discover' : 'Read More'} whiteButton />
        </Card>
      </a>
    </Link>
  );
};

export default ExpertiseCard;
